import Vue from "vue"
import VueFormulate from "@braid/vue-formulate"
import Autocomplete from "./Autocomplete"
import Editor from "./Editor"
import Selector from "./Selector"

import FormulateVSelectPlugin from "@cone2875/vue-formulate-select"

// !important
// Import the vue-select css
import "vue-select/dist/vue-select.css"

// register your component with Vue
Vue.component("Autocomplete", Autocomplete)
Vue.component("Editor", Editor)
Vue.component("Selector", Selector)

Vue.use(VueFormulate, {
	library: {
		autocomplete: {
			classification: "text",
			component: "Autocomplete",
		},
		editor: {
			classification: "text",
			component: "Editor",
			slotProps: {
				component: ["toolbar"],
			},
		},
		selector: {
			classification: "select",
			component: "Selector",
		},
	},
	plugins: [FormulateVSelectPlugin],
})
