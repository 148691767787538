import Vue from "vue"
/**
 * Global variables
 * Index
 * - strToString
 * - convertDate
 * - convertFiles
 * - uploader
 * - getTextLength
 * - formatPhoneNumber
 * - formatBytes
 * -
 */

export default ({ store, app }, inject) => {
	/**
	 * Restores insurer references to firestore format
	 * @param {object} insurers
	 * @returns {array}
	 */
	inject("restoreInsurers", (insurers) => {
		const payload = []
		if (insurers) {
			for (const insurer of insurers) {
				payload.push({
					id: insurer.id,
					ref_insurer: app.$fire.firestore.doc(`insurers/${insurer.ref_insurer.id}`),
					ref_paper: app.$fire.firestore.doc(
						`insurers/${insurer.ref_insurer.id}/papers/${insurer.ref_paper.id}`
					),
					percentage: insurer.percentage,
					type: insurer.type,
					common_name: insurer.common_name,
				})
			}
		}
		return payload
	})
	/**
	 * Return algolia index name with envorinment prefix
	 * @param {string} index
	 * @returns {string}
	 */
	inject("getAlgoliaIndex", (index) => {
		return app.$config.environment === "prod" ? index : `${app.$config.environment}_${index}`
	})
	/**
	 * Convert string to url slug e.g "Hello World." to "hello-world"
	 * @param {string} str
	 * @returns {string}
	 */
	inject("strToSlug", (str) => {
		if (str) {
			str = str.replace(/^\s+|\s+$/g, "") // trim
			str = str.toLowerCase()

			// remove accents, swap ñ for n, etc
			var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
			var to = "aaaaeeeeiiiioooouuuunc------"
			for (var i = 0, l = from.length; i < l; i++) {
				str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
			}

			str = str
				.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
				.replace(/\s+/g, "-") // collapse whitespace and replace by -
				.replace(/-+/g, "-") // collapse dashes

			return str
		} else {
			return ""
		}
	})
	/**
	 * Converts string date to firebase format
	 * @param {string} date
	 * @returns {object}
	 */
	inject("convertDate", (date) => {
		if (date) {
			return app.$fireModule.firestore.Timestamp.fromDate(
				new Date(app.$moment(date).set("hour", 2).set("minute", 0).set("second", 0).format())
			)
		}
	})
	/**
	 * Converts a date to ISO format YYYY-MM-DD
	 *
	 */
	inject("revertDate", (timestamp) => {
		if (timestamp) {
			return app.$moment.unix(timestamp).format("YYYY-MM-DD")
		}
	})
	/**
	 * Converts file array from Vue Formulate to Firebase Storage format
	 * @param {array} files
	 * @returns {array}
	 */
	inject("convertFiles", (files) => {
		if (files) {
			if (files.results) {
				let fileResults = JSON.parse(JSON.stringify(files.results))
				return fileResults.flat()
			} else {
				return files
			}
		} else {
			return []
		}
	})
	/**
	 * Uploader function for vue formulate
	 * @param {object} file
	 * @param {number} progress
	 * @returns {array}
	 */
	inject("uploader", async (file, progress) => {
		progress(0)
		const ref = app.$fire.firestore.collection("filesystem").doc()
		try {
			progress(10)
			const storageRef = app.$fire.storage.ref().child(`files/${ref.id}/${file.name}`)
			try {
				progress(30)
				await storageRef.put(file)
				progress(60)
				const storageUrl = await storageRef.getDownloadURL()
				const result = [
					{
						url: storageUrl,
						name: file.name,
						file_id: ref.id,
						size: file.size,
						type: file.type,
					},
				]
				progress(80)
				try {
					const payload = {
						url: storageUrl,
						name: file.name,
						size: file.size,
						type: file.type,
						date_created: app.$fireModule.firestore.FieldValue.serverTimestamp(),
					}
					if (store.state.uid) {
						payload.created_by = app.$fire.firestore.doc(`/users/${store.state.uid}`)
					}
					await ref.set(payload)
				} catch (err) {
					console.error(err)
					return
				}
				progress(100)
				return result
			} catch (err) {
				console.error(err)
				return
			}
		} catch (err) {
			console.error(err)
			return
		}
	})
	/**
	 * Returns a message specifying the amount of characters remaining in a text field
	 * @param {object} field
	 * @param {number} length
	 * @returns {string}
	 */
	inject("getTextLength", (field, length) => {
		if (field) {
			return `${length - field.length} characters left`
		} else {
			return `${length} character limit`
		}
	})

	/**
	 * Adds a prefix denoting size of storage
	 * @param {number} bytes
	 * @param {number} decimals
	 * @returns {string}
	 */
	inject("formatBytes", (bytes, decimals = 2) => {
		if (bytes === 0) return "0 Bytes"

		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

		const i = Math.floor(Math.log(bytes) / Math.log(k))

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
	})
	/**
	 * Formats a unix timestamp to a date with the format DD/MM/YYYY
	 * @param {number} timestamp
	 * @returns {string}
	 */
	inject("formatDate", (timestamp) => {
		if (!timestamp) {
			return "---"
		}
		return app.$moment.unix(timestamp).format("DD/MM/YYYY")
	})
	/**
	 * Formats a unix timestamp to a datetime with the format DD/MM/YYYY HH:mm:ss
	 * @param {number} timestamp
	 * @returns {string}
	 */
	inject("formatTimestamp", (timestamp) => {
		if (!timestamp) {
			return "---"
		}
		return app.$moment.unix(timestamp).format("DD/MM/YYYY HH:mm:ss")
	})
	/**
	 * Formats a unix timestamp to time since
	 * @param {number} timestamp
	 * @returns {string}
	 */
	inject("timeAgo", (timestamp) => {
		return app.$moment.unix(timestamp).fromNow()
	})
	/**
	 * Takes a user object and returns a display name
	 * @param {object} user
	 * @returns {string}
	 */
	inject("getUserName", (user) => {
		if (!user) {
			return "---"
		}
		return `${user.first_name} ${user.last_name}`
	})
	/**
	 * Takes a user object and returns a display name with a title
	 * @param {object} user
	 * @returns {string}
	 */
	inject("getUserNameWithTitle", (user) => {
		return `${user.title} ${user.first_name} ${user.last_name}`
	})
	/**
	 * Takes an object and clones it with only the specified keys
	 * @param {object} obj
	 * @param {array} keys
	 * @returns {object}
	 */
	inject("pick", (obj, keys) =>
		Object.keys(obj)
			.filter((i) => keys.includes(i))
			.reduce((acc, key) => {
				acc[key] = obj[key]
				return acc
			}, {})
	)
	/**
	 * Takes a date and returns it in a Firebase format
	 * @param {string} date
	 * @returns {object}
	 */
	inject("firebaseDateFormat", (date) => {
		return app.$fireModule.firestore.Timestamp.fromDate(new Date(app.$moment(date).format()))
	})

	/**
	 * Takes a url and returns the segments at the specified number
	 * @param {string} url
	 * @returns {string}
	 */
	inject("getSegment", (path, segment) => {
		const arr = path.split("/")
		return arr[segment]
	})
	/**
	 * Takes the three letter code for a currency and returns a symbol.
	 * @param {string} curr - the three letter currency code.
	 * @returns {string} - The symbol
	 */
	inject("getCurrencySymbol", (curr) => {
		switch (curr.toUpperCase()) {
			case "GBP":
				return "£"
			case "USD":
			case "CAD":
			case "AUD":
			case "HKD":
				return "$"
			case "EUR":
				return "€"
		}
	})
	/**
	 * Fetch a JSON list of countries with flags from a local file
	 */
	inject("getCountries", () => {
		/* A array of countries in json format including name, code, emoji flag, currency, and phone code. */
		return require("/static/json/countries.json")
	})
	/**
	 * Get the country name and emoji for a given country code
	 * @param {string} code - the two letter country code
	 * @returns {object} - the country name and emoji flag
	 */
	inject("getCountry", (code) => {
		const countries = require("/static/json/countries.json")
		const country = countries.find((c) => c.value === code)
		if (country) {
			return `${country.emoji} ${country.label}`
		}
	})
	/**
	 * Formats a string number to a currency format
	 * @param {string} value - The number to be formatted as a currency
	 * @param {string} currency - the three letter currency code to format the number.
	 * @returns {string} - The formatted currency with symbol
	 */
	inject("formatCurrency", (value, currency) => {
		if (value === undefined || value === null || isNaN(value)) {
			return "---"
		}
		const currencyCode = currency.toUpperCase()
		let formatter = new Intl.NumberFormat("en-GB", {
			style: "currency",
			currency: currencyCode,
		})
		return formatter.format(value)
	})
	/**
	 * Returns the current firebase server date in ISO format YYYY-MM-ddd
	 * @returns {string}
	 */
	inject("getServerTime", async (format) => {
		let params = ""
		if (format) {
			params = `?format=${format}`
		}
		try {
			const res = await fetch(`${app.$config.functionsUrl}utilities-getServerTime${params}`)
			const today = await res.text()
			return today
		} catch (err) {
			console.error(err)
			return
		}
	})
	/**
	 * Save activity to a subcollection of the user
	 * @param {string} uid
	 * @param {string} description
	 * @param {string} path
	 */
	inject("activityLog", async (uid, description, path) => {
		const ref = app.$fire.firestore.collection(`users/${uid}/activity`).doc()
		try {
			await ref.set({
				date_activity: app.$fireModule.firestore.FieldValue.serverTimestamp(),
				description: description,
				path: path,
			})
		} catch (err) {
			console.error(err)
			return
		}
	})
	/** Truncate a string
	 * @param {string} str
	 */
	inject("truncate", (str, n = 20) => {
		return str.length > n ? str.slice(0, n - 1) + "…" : str
	})

	/** Gets the assured name from a Risk ID
	 * @param {string} riskId
	 * @returns {string}
	 */
	inject("getAssuredName", async (riskId) => {
		const risk = await app.$fire.firestore.collection("risks").doc(riskId).get()
		return risk.data().assured_name
	})

	/**
	 * Trim a string and add ellipsis
	 * @param {string} str
	 * @param {number} length
	 * @returns {string}
	 */
	inject("trimString", (str, length) => {
		return str.length > length ? str.slice(0, length - 1) + "…" : str
	})

	/**
	 * Returns true if the user is a viewer\reader
	 * @returns {boolean}
	 */
	inject("isViewer", () => {
		return store.state.user.role === "viewer"
	})
	/**
	 * Returns true if the user is an admin
	 * @returns {boolean}
	 */
	inject("isAdmin", () => {
		return store.state.user.role === "admin"
	})
}
