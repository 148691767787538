export default ({ app }, inject) => {
	let startTime = 0

	const startSession = (userId) => {
		startTime = Date.now() // Capture the current time as start time
		console.log("Session started at:", new Date(startTime))

		// Set the user ID for the session
		if (process.client && window.firebase && userId) {
			window.firebase.analytics().setUserId(userId)
		}
	}

	const endSession = () => {
		const endTime = Date.now() // Capture the current time as end time
		if (startTime === 0) {
			console.log("Session start time not set correctly.")
			return
		}

		const sessionLength = endTime - startTime // Calculate session length
		console.log("Session ended at:", new Date(endTime))
		console.log("Session length:", sessionLength / 1000, "seconds")

		// Reset startTime to ensure correct calculation for the next session
		startTime = 0

		// Log session length to Firebase Analytics
		if (process.client && window.firebase) {
			window.firebase.analytics().logEvent("session_duration", { sessionLength })
		}
	}

	// Listen for visibility changes to track session start and end
	document.addEventListener("visibilitychange", () => {
		// Obtain user ID somehow, perhaps from store or localStorage
		const userId = app.store.state.uid // This is an example; adapt as needed

		if (document.visibilityState === "visible") {
			startSession(userId)
		} else {
			endSession()
		}
	})

	inject("startSession", startSession)
	inject("endSession", endSession)
}
