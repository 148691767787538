import { vuexfireMutations, firestoreAction } from "vuexfire"

const getDefaultState = () => {
	return {
		current: {},
		contacts: [],
	}
}

export const state = () => ({
	current: {},
	contacts: [],
})

export const mutations = {
	...vuexfireMutations,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
}

export const actions = {
	bind: firestoreAction(async function ({ bindFirestoreRef }, id) {
		const ref = this.$fire.firestore.collection("clients").doc(id)
		await bindFirestoreRef("current", ref, { wait: true })
	}),
	unbind: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("current", () => {
			return {}
		})
	}),
	bindContacts: firestoreAction(async function ({ bindFirestoreRef }, id) {
		const ref = this.$fire.firestore.collection("clients").doc(id).collection("contacts")
		await bindFirestoreRef("contacts", ref, { wait: true, maxRefDepth: 0 })
	}),
	unbindContacts: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("contacts", () => {
			return []
		})
	}),
	reset({ commit, dispatch }) {
		dispatch("unbind")
		dispatch("unbindContacts")
		commit("RESET_STATE")
	},
}

export const getters = {
	getCurrent(state) {
		return state.current
	},
	getContacts(state) {
		return state.contacts
	},
}
