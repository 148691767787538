import { vuexfireMutations, firestoreAction } from "vuexfire"

const getDefaultState = () => {
	return {
		index: [],
		current: {},
		filteredIndex: [],
	}
}

export const state = () => ({
	index: [],
	current: {},
	filteredIndex: [],
})

export const mutations = {
	...vuexfireMutations,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
}

export const actions = {
	bind: firestoreAction(async function ({ bindFirestoreRef }, path) {
		const ref = this.$fire.firestore.doc(path)
		await bindFirestoreRef("current", ref, { wait: true, maxRefDepth: 1 })
	}),
	unbind: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("current", () => {
			return {}
		})
	}),
	bindIndex: firestoreAction(async function ({ bindFirestoreRef }, collection) {
		// Risk ID
		const ref = this.$fire.firestore.collection(`${collection}/transactions`).orderBy("date_transaction", "desc")
		await bindFirestoreRef("index", ref, { wait: true, maxRefDepth: 1 })
	}),
	unbindIndex: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("index", () => {
			return []
		})
	}),
	bindFilteredIndex: firestoreAction(async function ({ bindFirestoreRef }, { policyPath, sourcePath }) {
		const sourceRef = this.$fire.firestore.doc(sourcePath)
		const ref = this.$fire.firestore
			.doc(policyPath)
			.collection("transactions")
			.where("ref_source", "==", sourceRef)
			.orderBy("date_transaction", "desc")
		await bindFirestoreRef("filteredIndex", ref, { wait: true, maxRefDepth: 1 })
	}),
	unbindFilteredIndex: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("filteredIndex", () => {
			return []
		})
	}),
	reset({ commit, dispatch }) {
		dispatch("unbind")
		dispatch("unbindIndex")
		dispatch("unbindFilteredIndex")
		commit("RESET_STATE")
	},
}

export const getters = {
	getCurrent(state) {
		return state.current
	},
	getIndex(state) {
		return state.index
	},
	getFilteredIndex(state) {
		return state.filteredIndex
	},
}
