
import { mapGetters } from "vuex"
import {
	PhBook,
	PhLayout,
	PhDiamondsFour,
	PhArchiveTray,
	PhExport,
	PhFilePlus,
	PhBuildings,
	PhPen,
	PhUserList,
	PhFunnelSimple,
	PhSyringe,
	PhFileSearch,
	PhUserCircle,
	PhListDashes,
	PhGearSix,
	PhUserFocus,
	PhCalculator,
	PhFaders,
	PhMoney,
} from "phosphor-vue"
export default {
	data() {
		return {}
	},
	components: {
		PhBook,
		PhLayout,
		PhDiamondsFour,
		PhArchiveTray,
		PhExport,
		PhFilePlus,
		PhBuildings,
		PhPen,
		PhUserList,
		PhFunnelSimple,
		PhSyringe,
		PhFileSearch,
		PhUserCircle,
		PhListDashes,
		PhGearSix,
		PhUserFocus,
		PhCalculator,
		PhFaders,
		PhMoney,
	},
	computed: {
		...mapGetters({
			user: "user",
		}),
	},
}
