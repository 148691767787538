
import { PhCaretDown, PhX } from "phosphor-vue"

export default {
	data() {
		return {
			menu: "closed",
		}
	},
	computed: {
		menuState() {
			return this.menu
		},
		alignmentClass() {
			let type = this.alignment
			return "drop-menu--" + type
		},
	},
	components: {
		PhCaretDown,
		PhX,
	},
	props: {
		label: {
			type: String,
			default: "",
		},
		alignment: {
			type: String,
			default: "left",
		},
	},
	methods: {
		closeMenu() {
			if (this.menu == "open") {
				this.menu = "closed"
			}
		},
	},
}
