// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/default-bg@2x.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".guard__grid[data-v-1414f100]{display:grid;grid-template-columns:2fr 1fr;min-height:100vh}.guard__content[data-v-1414f100]{min-height:100vh;display:flex;flex-direction:column;justify-content:center}.guard__content__wrapper[data-v-1414f100]{width:100%;max-width:30rem;margin:0 auto}.guard__display[data-v-1414f100]{background-color:#8267cb;position:relative;background:linear-gradient(45deg,#8267cb,#2d1e58);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;display:grid;justify-items:center;place-items:center;align-items:center}.guard__display[data-v-1414f100]:after{content:\"\";display:block;position:absolute;z-index:1;top:0;left:0;width:100%;height:100%;background:linear-gradient(45deg,rgba(130,103,203,.35),rgba(45,30,88,.2))}.branding[data-v-1414f100]{width:10rem}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
