import { vuexfireMutations, firestoreAction } from "vuexfire"

const getDefaultState = () => {
	return {
		policies: [],
		mtas: [],
	}
}

export const state = () => ({
	policies: [],
	mtas: [],
})

export const mutations = {
	...vuexfireMutations,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
}

export const actions = {
	bind: firestoreAction(async function ({ bindFirestoreRef }, currency) {
		// Risk , enquiry, quote id
		/*
		const policyRef = this.$fire.firestore
			.collectionGroup("policies")
			.where("realisation_available", "==", true)
			.where("currency", "==", currency)
			.limit(5)
		const mtasRef = this.$fire.firestore
			.collectionGroup("mtas")
			.where("realisation_available", "==", true)
			.where("currency", "==", currency)
			.limit(5)
		await bindFirestoreRef("policies", policyRef, { wait: true })
		await bindFirestoreRef("mtas", mtasRef, { wait: true })
        */
	}),
	unbind: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("policies", () => {
			return []
		})
		unbindFirestoreRef("mtas", () => {
			return []
		})
	}),
	reset({ commit, dispatch }) {
		dispatch("unbind")
		commit("RESET_STATE")
	},
}

export const getters = {
	getItems(state) {
		// Merge policies and mtas
		let items = []

		for (const policy of state.policies) {
			items.push({
				...policy,
				path: `risks/${policy.ref_risk.id}/enquiries/${policy.ref_enquiry.id}/policies/${policy.id}`,
			})
		}

		for (const mta of state.mtas) {
			items.push({
				...mta,
				path: `risks/${mta.ref_risk.id}/enquiries/${mta.ref_enquiry.id}/policies/${mta.ref_policy.id}/mtas/${mta.id}`,
			})
		}

		// Sort by date
		items.sort((a, b) => {
			return a.date_created?.seconds - b.date_created?.seconds
		})

		return items
	},
}
