
export default {
	computed: {
		classes() {
			return {
				[`well--${this.type}`]: true,
			}
		},
	},
	props: {
		padding: {
			type: String,
			default: "1rem",
		},
		margin: {
			type: String,
			default: "0",
		},
		type: {
			type: String,
			default: "default",
		},
	},
}
