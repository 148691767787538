// Enquiry index filters
const getDefaultState = () => {
	return {
		filters: {
			query: "",
			currentPage: 0,
		},
	}
}

export const state = () => getDefaultState()

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_FILTER(state, { filterName, value }) {
		state.filters[filterName] = value
	},
}

export const actions = {
	reset({ commit }) {
		commit("RESET_STATE")
	},
	updateFilter({ commit }, { filterName, value }) {
		commit("SET_FILTER", { filterName, value })
	},
}

export const getters = {
	getFilters(state) {
		return state.filters
	},
	getFilter: (state) => (filterName) => {
		return state.filters[filterName]
	},
}
