
import { Editor, EditorContent, BubbleMenu, FloatingMenu } from "@tiptap/vue-2"
import Table from "@tiptap/extension-table"
import TableRow from "@tiptap/extension-table-row"
import TableCell from "@tiptap/extension-table-cell"
import TableHeader from "@tiptap/extension-table-header"
import Link from "@tiptap/extension-link"
import StarterKit from "@tiptap/starter-kit"
export default {
	data() {
		return {
			editor: null,
		}
	},
	beforeUnmount() {
		this.editor.destroy()
	},
	mounted() {
		this.editor = new Editor({
			content: this.context.model,
			extensions: [
				StarterKit,
				Table.configure({
					resizable: true,
				}),
				TableRow,
				TableHeader,
				TableCell,
				Link.configure({
					protocols: ["mailto"],
				}),
			],
			onUpdate: () => {
				// HTML
				this.context.model = this.editor.getHTML()

				// JSON
				// this.$emit('input', this.editor.getJSON())
			},
		})
	},
	components: {
		EditorContent,
		BubbleMenu,
		FloatingMenu,
	},
	computed: {
		model() {
			return this.context.model
		},
	},
	methods: {
		setLink() {
			const previousUrl = this.editor.getAttributes("link").href
			const url = window.prompt("URL", previousUrl)

			// cancelled
			if (url === null) {
				return
			}

			// empty
			if (url === "") {
				this.editor.chain().focus().extendMarkRange("link").unsetLink().run()

				return
			}

			// update link
			this.editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run()
		},
	},
	watch: {
		value(value) {
			// HTML
			const isSame = this.editor.getHTML() === value

			// JSON
			// const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

			if (isSame) {
				return
			}

			this.editor.commands.setContent(value, false)
		},
	},
	props: {
		context: {
			type: Object,
			required: true,
		},
		toolbar: {
			type: Boolean,
			default: true,
		},
		value: {
			type: String,
			default: "",
		},
	},
}
