// Policy index filters
const getDefaultState = () => {
	return {
		filters: {
			query: "",
			status: "",
			broker_id: "",
			technician_id: "",
			entity_id: "",
			class: "",
			subclass: "",
			trade: "",
			business_type: "",
			market: "",
			includeArchive: false,
			currentPage: 0,
		},
	}
}

export const state = () => getDefaultState()

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_FILTER(state, { filterName, value }) {
		state.filters[filterName] = value
	},
	SET_INCLUDE_ARCHIVE(state, value) {
		state.filters.includeArchive = value
	},
}

export const actions = {
	reset({ commit }) {
		commit("RESET_STATE")
	},
	updateFilter({ commit }, { filterName, value }) {
		commit("SET_FILTER", { filterName, value })
	},
	updateIncludeArchive({ commit }, value) {
		commit("SET_INCLUDE_ARCHIVE", value)
	},
}

export const getters = {
	getFilters(state) {
		return state.filters
	},
	getFilter: (state) => (filterName) => {
		return state.filters[filterName]
	},
	getIncludeArchive(state) {
		return state.filters.includeArchive
	},
}
