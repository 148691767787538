
export default {
	mounted: function () {
		document.addEventListener("keydown", (e) => {
			if (e.keyCode == 27) {
				this.close()
			}
		})
	},
	computed: {
		isLoading() {
			if (this.$props.status === "loading") return true
			else return false
		},
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		heading: {
			type: String,
		},
		actionLabel: {
			type: String,
		},
		dangerLabel: {
			type: String,
		},
		closeLabel: {
			type: String,
			default: "Close",
		},
		large: {
			type: Boolean,
			default: false,
		},
		status: {
			type: String,
		},
		show: {
			type: Boolean,
		},
		step: {
			type: Number,
		},
		totalSteps: {
			type: Number,
		},
		isSaving: {
			type: Boolean,
			default: false,
		},
		disableControls: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		close() {
			this.$emit("close")
		},
		action() {
			this.$emit("action")
		},
		danger() {
			this.$emit("danger")
		},
	},
}
