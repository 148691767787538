import { vuexfireMutations, firestoreAction } from "vuexfire"

const getDefaultState = () => {
	return {
		index: [],
	}
}

export const state = () => ({
	index: [],
})

export const mutations = {
	...vuexfireMutations,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
}

export const actions = {
	bindIndex: firestoreAction(async function ({ bindFirestoreRef }, document) {
		// Risk ID
		const ref = this.$fire.firestore
			.collection(`${document.collection}/${document.id}/notes`)
			.orderBy("date_created", "desc")
		await bindFirestoreRef("index", ref, { wait: true, maxRefDepth: 1 })
	}),
	unbindIndex: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("index", () => {
			return []
		})
	}),
	reset({ commit, dispatch }) {
		dispatch("unbindIndex")
		commit("RESET_STATE")
	},
}

export const getters = {
	getIndex(state) {
		return state.index
	},
}
