
export default {
	props: {
		minHeight: {
			type: String,
			default: "5rem",
		},
		muted: {
			type: Boolean,
			default: false,
		},
	},
}
