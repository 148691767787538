import { vuexfireMutations, firestoreAction } from "vuexfire"

const getDefaultState = () => {
	return {
		index: [],
		current: {},
	}
}

export const state = () => ({
	index: [],
	current: {},
})

export const mutations = {
	...vuexfireMutations,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
}

export const actions = {
	bind: firestoreAction(async function ({ bindFirestoreRef }, doc) {
		const ref = this.$fire.firestore.doc(`insurers/${doc.id}/papers/${doc.slug}`)
		await bindFirestoreRef("current", ref, { wait: true })
	}),
	unbind: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("current", () => {
			return []
		})
	}),
	bindIndex: firestoreAction(async function ({ bindFirestoreRef }, id) {
		const ref = this.$fire.firestore.collection(`insurers/${id}/papers`)
		await bindFirestoreRef("index", ref, { wait: true })
	}),
	unbindIndex: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("index", () => {
			return []
		})
	}),
	reset({ commit, dispatch }) {
		dispatch("unbindIndex")
		dispatch("unbind")
		commit("RESET_STATE")
	},
}

export const getters = {
	getIndex(state) {
		return state.index
	},
	getCurrent(state) {
		return state.current
	},
	getLabelledIndex(state) {
		return state.index.map((item) => {
			return {
				label: item.label,
				value: item.id,
			}
		})
	},
}
