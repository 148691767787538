
import { mapActions, mapGetters } from "vuex"
import { PhInfo, PhMoon, PhSun, PhSignOut, PhArrowLineLeft, PhArrowLineRight, PhMicrosoftTeamsLogo } from "phosphor-vue"
export default {
	data() {
		return {
			modalOpen: false,
		}
	},
	components: {
		PhInfo,
		PhMoon,
		PhSun,
		PhSignOut,
		PhArrowLineLeft,
		PhArrowLineRight,
		PhMicrosoftTeamsLogo,
	},
	computed: {
		...mapGetters({
			uid: "uid",
			user: "user",
			isMicrosoftLinked: "isMicrosoftLinked",
		}),
	},
	methods: {
		...mapActions(["signOutAction", "linkToMsAccountAction"]),
		toggleDarkmode() {
			const ref = this.$fire.firestore.collection("users").doc(this.uid)
			try {
				ref.update({
					darkmode_active: !this.user.darkmode_active,
				})
			} catch (err) {
				console.error(err)
				return
			}
		},
		toggleDrawer() {
			const ref = this.$fire.firestore.collection("users").doc(this.uid)
			try {
				ref.update({
					drawer_open: !this.user.drawer_open,
				})
			} catch (err) {
				console.error(err)
				return
			}
		},
		confirmSignOut() {
			if (this.$isViewer) {
				this.signOutAction()
			}
			this.modalOpen = true
		},
	},
}
