import { vuexfireMutations, firestoreAction } from "vuexfire"

const getDefaultState = () => {
	return {
		index: [],
		current: {},
	}
}

export const state = () => ({
	index: [],
	current: {},
})

export const mutations = {
	...vuexfireMutations,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
}

export const actions = {
	bindCurrent: firestoreAction(async function ({ bindFirestoreRef }, docPath) {
		const ref = this.$fire.firestore.doc(docPath)
		await bindFirestoreRef("current", ref, { wait: true, maxRefDepth: 2 })
	}),
	unbindCurrent: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("current", () => {
			return {}
		})
	}),
	bindIndex: firestoreAction(async function ({ bindFirestoreRef }, payload) {
		const ref = this.$fire.firestore
			.collection(`risks/${payload.risk_id}/enquiries/${payload.enquiry_id}/policies/${payload.policy_id}/mtas`)
			.orderBy("date_created", "asc")
		await bindFirestoreRef("index", ref, { wait: true, maxRefDepth: 0 })
	}),
	unbindIndex: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("index", () => {
			return []
		})
	}),
	reset({ commit, dispatch }) {
		dispatch("unbindIndex")
		dispatch("unbindCurrent")
		commit("RESET_STATE")
	},
}

export const getters = {
	getIndex(state) {
		return state.index
	},
	getCurrent(state) {
		return state.current
	},
}
