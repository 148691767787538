import { vuexfireMutations, firestoreAction } from "vuexfire"

const getDefaultState = () => {
	return {
		current: {},
		index: [],
		bank_accounts: [],
	}
}

export const state = () => ({
	current: {},
	index: [],
	bank_accounts: [],
})

export const mutations = {
	...vuexfireMutations,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
}

export const actions = {
	bindIndex: firestoreAction(async function ({ bindFirestoreRef }) {
		// Sort oldest to newest
		const ref = this.$fire.firestore.collection("entities")
		await bindFirestoreRef("index", ref, { wait: true })
	}),
	unbindIndex: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("index", () => {
			return []
		})
	}),
	bindCurrent: firestoreAction(async function ({ bindFirestoreRef }, id) {
		const ref = this.$fire.firestore.collection("entities").doc(id)
		await bindFirestoreRef("current", ref, { wait: true })
	}),
	unbindCurrent: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("current", () => {
			return {}
		})
	}),
	bindAccounts: firestoreAction(async function ({ bindFirestoreRef }, id) {
		const ref = this.$fire.firestore.collection(`entities/${id}/bank_accounts`)
		await bindFirestoreRef("bank_accounts", ref, { wait: true })
	}),
	unbindAccounts: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("bank_accounts", () => {
			return []
		})
	}),
	reset({ commit, dispatch }) {
		dispatch("unbindCurrent")
		dispatch("unbindIndex")
		commit("RESET_STATE")
	},
}

export const getters = {
	getIndex(state) {
		return state.index
	},
	getLabelledIndex(state) {
		const transformed = state.index.map(({ name, id }) => ({
			label: name,
			value: id,
		}))
		// Move value 'uk' to the top if it exists
		const ukIndex = transformed.findIndex(({ value }) => value === "uk")
		if (ukIndex > -1) {
			const uk = transformed.splice(ukIndex, 1)

			transformed.unshift(uk[0])
		}
		return transformed
	},
	getCurrent(state) {
		return state.current
	},
	getAccounts(state) {
		return state.bank_accounts
	},
}
