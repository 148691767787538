
import { PhCircleNotch } from "phosphor-vue"
export default {
	components: {
		PhCircleNotch,
	},
	props: {
		size: {
			type: Number,
			default: 18,
		},
	},
}
