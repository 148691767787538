import { vuexfireMutations, firestoreAction } from "vuexfire"

const getDefaultState = () => {
	return {
		adjustment_snapshots: [],
		snapshots: [],
		current: {},
	}
}

export const state = () => ({
	adjustment_snapshots: [],
	snapshots: [],
	current: {},
})

export const mutations = {
	...vuexfireMutations,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
}

export const actions = {
	bindAdjustmentSnapshots: firestoreAction(async function ({ bindFirestoreRef }) {
		const ref = this.$fire.firestore.collection("adjustment_snapshots").orderBy("date_created", "desc")
		await bindFirestoreRef("adjustment_snapshots", ref, { wait: true, maxRefDepth: 1 })
	}),
	unbindAdjustmentSnapshots: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("adjustment_snapshots", () => {
			return []
		})
	}),
	bindSnapshots: firestoreAction(async function ({ bindFirestoreRef }, docPath) {
		const ref = this.$fire.firestore.doc(docPath).collection("snapshots").orderBy("date_created", "desc")
		await bindFirestoreRef("snapshots", ref, { wait: true, maxRefDepth: 1 })
	}),
	unbindSnapshots: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("snapshots", () => {
			return []
		})
	}),
	bindCurrent: firestoreAction(async function ({ bindFirestoreRef }, docPath) {
		const ref = this.$fire.firestore.doc(docPath)
		await bindFirestoreRef("current", ref, { wait: true, maxRefDepth: 1 })
	}),
	unbindCurrent: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("current", () => {
			return {}
		})
	}),
	reset({ commit, dispatch }) {
		dispatch("unbindAdjustmentSnapshots")
		dispatch("unbindSnapshots")
		dispatch("unbindCurrent")
		commit("RESET_STATE")
	},
}

export const getters = {
	getAdjustmentSnapshots(state) {
		return state.adjustment_snapshots
	},
	getSnapshots(state) {
		return state.snapshots
	},
	getCurrent(state) {
		return state.current
	},
}
