import { vuexfireMutations, firestoreAction } from "vuexfire"

const getDefaultState = () => {
	return {
		settings: {},
	}
}

export const state = () => ({
	settings: {},
})

export const mutations = {
	...vuexfireMutations,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
}

export const actions = {
	bindSettings: firestoreAction(async function ({ bindFirestoreRef }) {
		const ref = this.$fire.firestore.collection("globals").doc("settings")
		await bindFirestoreRef("settings", ref, { wait: true })
	}),
	unbindSettings: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("settings", () => {
			return {}
		})
	}),
	reset({ commit, dispatch }) {
		dispatch("unbindSettings")
		commit("RESET_STATE")
	},
}

export const getters = {
	getSettings(state) {
		return state.settings
	},
}
