import { vuexfireMutations, firestoreAction } from "vuexfire"

const getDefaultState = () => {
	return {
		index: [],
		filters: {
			status: "",
			business_type: "",
			class: "",
			jurisdiction: "",
			entity: "",
		},
	}
}

export const state = () => ({
	index: [],
	filters: {
		status: "",
		business_type: "",
		class: "",
		jurisdiction: "",
		entity: "",
	},
})

export const mutations = {
	...vuexfireMutations,
	SET_FILTERS(state, payload) {
		state.filters = payload
	},
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
}

export const actions = {
	bindIndex: firestoreAction(async function ({ bindFirestoreRef }, ref) {
		/*
		let ref = this.$fire.firestore.collectionGroup("enquiries").orderBy("date_created", "asc")
		// If broker is logged in, bind to their enquiries
		if (id) {
			// Create broker ref
			const brokerRef = this.$fire.firestore.collection("user").doc(id)
			ref = this.$fire.firestore
				.collectionGroup("enquiries")
				.orderBy("date_created", "asc")
				.where("ref_broker", "==", brokerRef)
		}*/
		await bindFirestoreRef("index", ref, { wait: true, maxRefDepth: 1 })
	}),
	unbindIndex: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("index", () => {
			return []
		})
	}),
	reset({ commit, dispatch }) {
		dispatch("unbindIndex")
		commit("RESET_STATE")
	},
}

export const getters = {
	getIndex(state) {
		return state.index
	},
	getFilters(state) {
		return state.filters
	},
}
