import { vuexfireMutations, firestoreAction } from "vuexfire"

const getDefaultState = () => {
	return {
		current: {},
	}
}

export const state = () => ({
	current: {},
})

export const mutations = {
	...vuexfireMutations,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
}

export const actions = {
	bind: firestoreAction(async function ({ bindFirestoreRef }, payload) {
		const ref = this.$fire.firestore
			.collection(`insurers/${payload.insurer_id}/underwriters`)
			.doc(payload.underwriter_id)
		await bindFirestoreRef("current", ref, { wait: true })
	}),
	unbind: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("current", () => {
			return {}
		})
	}),
	reset({ commit, dispatch }) {
		dispatch("unbind")
		commit("RESET_STATE")
	},
}

export const getters = {
	getCurrent(state) {
		return state.current
	},
}
