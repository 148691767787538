import { render, staticRenderFns } from "./Editor.vue?vue&type=template&id=fe96799c&"
import script from "./Editor.vue?vue&type=script&lang=js&"
export * from "./Editor.vue?vue&type=script&lang=js&"
import style0 from "./Editor.vue?vue&type=style&index=0&id=fe96799c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Menu: require('/Users/azeem/Projects/stratify-beta-nuxt2-firebase/components/Menu.vue').default,Dropdown: require('/Users/azeem/Projects/stratify-beta-nuxt2-firebase/components/Dropdown.vue').default})
