const getDefaultState = () => {
	return {
		index: [],
	}
}

export const state = () => ({
	index: [],
})

export const mutations = {
	SET_INDEX(state, docs) {
		state.index = docs
	},
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
}

export const actions = {
	async bindIndex({ commit }) {
		const ref = this.$fire.firestore.collection("ipt")
		ref.onSnapshot((snapshot) => {
			const docs = snapshot.docs.map((doc) => ({
				id: doc.id,
				label: doc.data().label,
				rate: doc.data().rate || 0,
			}))
			commit("SET_INDEX", docs) // Always use commit to change state
		})
	},
	reset({ commit, dispatch }) {
		commit("RESET_STATE")
	},
}

export const getters = {
	getIndex(state) {
		return state.index
	},
	getLabelledIndex(state) {
		const transformed = state.index.map(({ label, rate }) => ({
			label: label,
			value: rate,
		}))
		return transformed
	},
}
