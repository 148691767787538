import { vuexfireMutations, firestoreAction } from "vuexfire"
import VuexPersistence from "vuex-persist"
import Crypto from "crypto-js"
import Cookie from "js-cookie"
import firebase from "firebase/app"
import "firebase/auth"

const env = process.env.NODE_ENV === "prod" ? "prod" : "dev"

const cookieName = `${env}__STRBC`
const storageKey = `${env}__STRBK`

// Get the encryption token from cookie or generate a new one.
const encryptionToken = Cookie.get(cookieName) || crypto.randomUUID()

// Store the encryption token in a secure cookie.
Cookie.set(cookieName, encryptionToken, { secure: true, expires: 180 })

const vuexLocal = new VuexPersistence({
	key: storageKey,
	storage: {
		getItem: () => {
			// Get the store from local storage.
			const store = window.localStorage.getItem(storageKey)
			if (store) {
				try {
					// Decrypt the store retrieved from local storage
					// using our encryption token stored in cookies.
					const bytes = Crypto.AES.decrypt(store, encryptionToken)
					return JSON.parse(bytes.toString(Crypto.enc.Utf8))
				} catch (e) {
					// The store will be reset if decryption fails.
					window.localStorage.removeItem(storageKey)
				}
			}

			return null
		},
		setItem: (key, value) => {
			// Encrypt the store using our encryption token stored in cookies.
			const store = Crypto.AES.encrypt(value, encryptionToken).toString()
			// Save the encrypted store in local storage.
			return window.localStorage.setItem(storageKey, store)
		},
		removeItem: () => {
			window.localStorage.removeItem(storageKey)
		},
	},
})

const getDefaultState = () => {
	return {
		uid: "",
		email: "",
		user: "",
		title: "",
		error: "",
		linkStatus: "",
		isMicrosoftLinked: false,
	}
}

export const state = () => ({
	uid: "",
	email: "",
	user: "",
	title: "",
	error: "",
	linkStatus: "",
	isMicrosoftLinked: false,
})

export const mutations = {
	SET_ERROR(state, error) {
		state.error = error
	},
	SET_SIGN_IN_STATUS(state, status) {
		state.auth = status
	},
	SET_USER(state, user) {
		state.uid = user.uid
		state.email = user.email
	},
	SET_PAGE_TITLE(state, title) {
		state.title = title
	},
	SET_LINK_STATUS(state, status) {
		state.linkStatus = status
	},
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_MICROSOFT_LINKED(state, isLinked) {
		state.isMicrosoftLinked = isLinked
	},
	RESTORE_STATE(state, payload) {
		state = payload
	},
	RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
	...vuexfireMutations,
}

export const actions = {
	async onAuthStateChangedAction({ commit, dispatch, state }, { authUser, claims }) {
		if (authUser) {
			await dispatch("bindUserData", authUser.uid)
			// User is signed in, set the user ID in analytics
			this.$fire.analytics.setUserId(authUser.uid)
		} else {
			// User is signed out, unset the user ID
			this.$fire.analytics.setUserId(null)
		}
	},
	async signInAction({ commit, dispatch, state }, payload) {
		commit("SET_ERROR", "")
		commit("SET_SIGN_IN_STATUS", "loading")
		try {
			const users = await this.$fire.auth.signInWithEmailAndPassword(payload.email, payload.password)
			await commit("SET_USER", users.user)
			commit("SET_SIGN_IN_STATUS", "active")

			// Check if the user's account is linked with Microsoft
			const isMicrosoftLinked = users.user.providerData.some(
				(provider) => provider.providerId === "microsoft.com"
			)

			console.log("isMicrosoftLinked: ", isMicrosoftLinked)
			commit("SET_MICROSOFT_LINKED", isMicrosoftLinked)

			await dispatch("bindUserData", users.user.uid)

			return state.user
		} catch (err) {
			console.log(err)
			commit("SET_SIGN_IN_STATUS", "null")
			commit("SET_ERROR", err.message)
		}
	},
	async signInWithMsAction({ commit, dispatch, state }) {
		const provider = new firebase.auth.OAuthProvider("microsoft.com")

		provider.setCustomParameters({
			tenant: "common", // Use 'common' to allow sign-in from any Microsoft account
		})

		commit("SET_ERROR", "")
		commit("SET_SIGN_IN_STATUS", "loading")

		try {
			const result = await this.$fire.auth.signInWithPopup(provider)
			console.log("Signed in with Microsoft:", result.user)
			commit("SET_USER", result.user)
			commit("SET_SIGN_IN_STATUS", "active")

			await dispatch("bindUserData", result.user.uid)
			console.log("Bound user data.")

			return state.user
		} catch (error) {
			console.error("Error during Microsoft sign in:", error)
			commit("SET_SIGN_IN_STATUS", "null")
			commit("SET_ERROR", error.message)

			if (error.code === "auth/account-exists-with-different-credential") {
				const email = (error.customData && error.customData.email) || ""
				console.error("Email during Microsoft sign in:", email)
				if (email) {
					try {
						const methods = await this.$fire.auth.fetchSignInMethodsForEmail(email)
						console.error("Methods:", methods)
						if (methods.includes("password")) {
							commit(
								"SET_ERROR",
								"Email already exists. Please sign in with your email and password first and link your account with Microsoft account."
							)
							console.log("Please sign in with your email and password first.")
						}
					} catch (fetchError) {
						console.error("Error fetching sign-in methods:", fetchError)
						commit("SET_ERROR", fetchError.message)
					}
				} else {
					console.error("No email available from the error object.")
				}
			}
		}
	},
	// Vuex Action
	async linkToMsAccountAction({ commit, state }) {
		const provider = new firebase.auth.OAuthProvider("microsoft.com")
		provider.setCustomParameters({ tenant: "common" })

		commit("SET_ERROR", "")
		commit("SET_LINK_STATUS", "loading") // Assuming you have a mutation for link status

		try {
			console.log("Auth: ", this.$fire.auth)
			const result = await this.$fire.auth.currentUser.linkWithPopup(provider)
			console.log("Signed in with Microsoft:", result.user)
			commit("SET_LINK_STATUS", "success")
			console.log("Microsoft account linked successfully")
			commit("SET_MICROSOFT_LINKED", true)
			// Additional actions if necessary, like updating user info or storing linked accounts
		} catch (error) {
			console.error("Error during Microsoft sign in:", error)
			commit("SET_LINK_STATUS", "failed")
			commit("SET_ERROR", error.message)

			if (error.code === "auth/account-exists-with-different-credential") {
				const microsoftCredential = error.credential // Extract credential from error
				console.log("microsoftCredential:", microsoftCredential)

				try {
					await this.$fire.auth.linkWithCredential(this.$store.state.user, microsoftCredential)
					commit("SET_ERROR", null)
					alert("Microsoft account linked successfully")
				} catch (linkError) {
					console.error("Failed to link with Microsoft credential:", linkError)
					commit("SET_ERROR", linkError.message)
				}
			}
		}
	},
	async signUpAction({ commit, dispatch, state }, payload) {
		commit("SET_ERROR", "")
		commit("SET_SIGN_IN_STATUS", "loading")
		try {
			const users = await this.$fire.auth.createUserWithEmailAndPassword(payload.email, payload.password)
			await commit("SET_USER", users.user)
			await dispatch("bindUserData", users.user.uid)
			commit("SET_SIGN_IN_STATUS", "active")
			return state.user
		} catch (err) {
			commit("SET_SIGN_IN_STATUS", "null")
			commit("SET_ERROR", err.message)
		}
	},
	async signOutAction({ commit, dispatch }) {
		try {
			this.$router.replace({ path: "/" })
			this.$fire.auth.signOut()
			dispatch("resetState")
			window.localStorage.removeItem(storageKey)
		} catch (err) {
			console.log(err)
		}
	},
	async resetState({ commit, dispatch }) {
		commit("RESET_STATE")
	},
	bindUserData: firestoreAction(async function ({ bindFirestoreRef, state }, uid) {
		const ref = this.$fire.firestore.collection("users").doc(uid)
		await bindFirestoreRef("user", ref, { wait: true })
	}),
	unbindUserData: firestoreAction(function ({ unbindFirestoreRef }) {
		unbindFirestoreRef("user", () => {
			return {}
		})
	}),
}

export const getters = {
	uid(state) {
		if (state.uid && state.email) return state.uid
		else return null
	},
	email(state) {
		if (state.uid && state.email) return state.email
		else return null
	},
	user(state) {
		if (state.uid && state.email && state.user) return state.user
		else return null
	},
	error(state) {
		return state.error
	},
	loading(state) {
		return state.loading
	},
	title(state) {
		return state.title
	},
	role(state) {
		if (state.user && state.user.role) return state.user.role
		else return null
	},
	isAuthenticated(state) {
		if (state.uid && state.email) return true
		else return false
	},
	algoliaKey(state) {
		if (state.user && state.user.algolia_key) return state.user.algolia_key
		else return null
	},
	isAdmin(state) {
		if (state.user && state.user.role === "admin") return true
	},
	isBroker(state) {
		if (state.user && state.user.role === "broker") return true
	},
	isMicrosoftLinked(state) {
		return state.isMicrosoftLinked
  },
	isViewer(state) {
		if (state.user && state.user.role === "viewer") return true
	},
}

export const plugins = [vuexLocal.plugin]
